import { computed, inject, Injectable, signal } from '@angular/core';
import { combineLatest, retry, startWith, Subject, switchMap } from 'rxjs';
import { CommentList, CommentsService, PaginatedChildListMeta } from 'gen';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface CommentState {
  meta: PaginatedChildListMeta;
  comments: CommentList[];
  error: string | null;
  status: 'loading' | 'success' | 'error';
  currentPage: number;
}

@Injectable()
export class CustomCommentService {
  private state = signal<CommentState>({
    comments: [],
    meta: {
      nb_pages: 0,
      page_size: 0,
      next: null,
      previous: null,
    },
    error: null,
    status: 'loading',
    currentPage: 1,
  });

  private commentsService = inject(CommentsService);

  public comments = computed(() => this.state().comments);

  public meta = computed(() => this.state().meta);

  private error$ = new Subject<Error>();

  public currentPage$ = new Subject<number>();

  public embassyId$ = new Subject<string>();

  private commentForPage$ = this.currentPage$.pipe(startWith(1));

  private retry$ = new Subject<void>();

  public commentForIdAndPage$ = combineLatest([
    this.embassyId$,
    this.commentForPage$,
  ]).pipe(
    switchMap(([embassyId, page]) =>
      this.getComments(embassyId, page).pipe(
        retry({
          delay: err => {
            this.error$.next(err);
            return this.retry$;
          },
        })
      )
    )
  );

  constructor() {
    this.commentForIdAndPage$
      .pipe(takeUntilDestroyed())
      .subscribe(paginatedCommentList =>
        this.state.update(state => ({
          ...state,
          meta: paginatedCommentList.meta ?? state.meta,
          comments: [
            ...state.comments,
            ...(paginatedCommentList.results ?? []),
          ],
          status: 'success',
        }))
      );

    this.currentPage$.pipe(takeUntilDestroyed()).subscribe(currentPage =>
      this.state.update(state => ({
        ...state,
        currentPage,
        status: 'loading',
      }))
    );
  }

  getComments(embassyId: string, page: number) {
    return this.commentsService.commentsList({ embassyId, page });
  }
}
